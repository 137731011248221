import './App.css';
import { JoinForm } from './JoinForm';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://uploads-ssl.webflow.com/641504e10167933ad3a79109/6415066299260e14960eab11_PNG%20File.png" className="App-logo" alt="logo" />
        {/* <p className="App-subtitle">
          Coming Soon...
        </p> */}
        <JoinForm />
      </header>
    </div>
  );
}

export default App;
