import { useState } from 'react';
import { useForm } from "react-hook-form";

export const JoinForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [ submitStatus, changeSubmitStatus ] = useState(false);
  const onSubmit = data => {
    console.log('data: ', data);
    changeSubmitStatus(true);
  };

  return (
    <div class="join">
      {
        submitStatus ? (
          <>
            <h2>Declined</h2>
            <p>We have thoroughly reviewed your application and have decided that, unfortunately, you are not a fit for our team</p>
            <p>Maybe next year sport</p>
          </>
        ) : (
        <form class="join-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 class="typography-module--title" style={{ marginTop: '40px' }}>Join the Team</h2>
          <input class="join-form__input" {...register("firstName", { required: true })} placeholder="First Name"/>
          <input class="join-form__input" {...register("lastName", { required: true })} placeholder="Last Name"/>
          <input class="join-form__input" {...register("emailAddress", { required: true })} placeholder="Email Address"/>
          {errors.exampleRequired && <span>This field is required</span>}
          <input class="join-form__submit" type="submit" />
        </form>
        )
      }
    </div>
  );
}